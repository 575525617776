<template>
  <v-dialog v-model="dialogVisible" width="400" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn text v-bind="$attrs" v-on="on"
        ><v-icon left>mdi-account-group</v-icon> Gruppe hinzufügen</v-btn
      >
    </template>
    <v-card>
      <v-system-bar
        >Kurs auswählen<v-spacer /><v-btn icon @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >
      <v-toolbar flat>
        <CourseInput
          v-model="pickerValue"
          label="Kurse suchen"
          clearable
          hide-details
          @input="add"
      /></v-toolbar>
      <v-divider />
      <v-card-text class="pa-0 ma-0">
        <v-list>
          <v-list-item-group v-model="modelValue">
            <Course v-for="item in items" :key="item.id" :value="item" />
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="close">Abbrechen</v-btn>
        <v-spacer />
        <v-btn
          color="success"
          text
          @click="accept"
          :disabled="modelValue == null"
          >Mitglieder hinzufügen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Course from "@/components/Course.vue";
import CourseInput from "common/components/CourseInput.vue";

export default {
  name: "CoursePicker",
  components: { Course, CourseInput },
  props: {
    value: { type: Array },
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      modelValue: this.value,
      pickerValue: null,
    };
  },
  async created() {
    const term = await this.apiList({
      resource: "common/term",
      query: "current",
    });
    this.items = await this.apiList({
      resource: "course/course",
      query: `person=${this.profilePerson.id}&startDate=${term.startDate}&endDate=${term.endDate}`,
    });
  },
  methods: {
    accept() {
      if (this.modelValue) {
        this.$emit("input", this.modelValue);
        this.close();
      }
    },
    add(item) {
      if (item) {
        this.items.unshift(item);
        this.modelValue = item;
        window.setTimeout(() => (this.pickerValue = null), 100);
      }
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
